<template>
    <router-view/>
</template>

<script>
    export default {
        name: 'Purchase',

        data() {
            return {}
        },
    }
</script>
